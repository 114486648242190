import React from "react";
import PopupVideo from "../../components/popup-video";
import VideoData from "../../data/video.json";
import useMasonry from "../../hooks/use-masonry";

const VideoGalleryContainer = () => {
    const { categories } = useMasonry(VideoData, ".portfolio-list");
    return (
        <div className="portfolio-area portfolio-default-area">
            <div className="container-fluid">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 portfolio-list mb-n30">
                    <div className="col"></div>
                    {VideoData &&
                        VideoData.map((video) => (
                            <div
                                key={video.id}
                                className={`col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-30`}
                            >
                                <PopupVideo data={video} />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default VideoGalleryContainer;
