import { Link } from "react-router-dom";

const BookingCalendar = () => {
    return (
        <div className="booking-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-5" data-aos="fade-up">
                        <iframe
                            title="booking calendar"
                            id="booking-iframe"
                            sandbox="allow-top-navigation allow-scripts allow-same-origin"
                            style={{
                                width: "100%",
                                height: "auto",
                                minHeight: "800px",
                            }}
                            frameBorder="0"
                            src="https://booking.hospitable.com/widget/external/927678"
                        ></iframe>
                    </div>
                    <div className="col-md-7" data-aos="fade-up">
                        <p>
                            All payments require a 25% down payment. 25% due 30
                            days before check in. The remaining 50% will need to
                            be paid 14 days before check in. A full refund will
                            be paid if cancelled 30 before the check in date or
                            sooner. If the reservation is cancelled between 30
                            and 14 days before the check in date, only a 50%
                            refund will be paid. 14 days or less for
                            cancellation will result in a 0% refund.
                        </p>
                        <p>
                            The Studio has a maximum of 3 guests. Dogs are
                            allowed under 50lbs. Booking has a minimum of a 2
                            night stay. Once your booking is made, please allow
                            up to 24 hours for someone to contact you with
                            further guidance on making a deposit.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingCalendar;
