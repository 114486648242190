import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import Seo from "../../data/seo.json";

const SEO = ({ title }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Cozy Dreaming Studio – Airbnb" />
            <meta name="keywords" content={Seo.keywords} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
};

export default SEO;
