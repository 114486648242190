import React from "react";
import VideoData from "../../data/video.json";
import PopupVideo from "../../components/popup-video";

const SingleVideoContainer = () => {
    return (
        <div className="video-area">
            <div className="container-fluid">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-xl-4 single-video mb-n30">
                    <div className="col"></div>
                    {VideoData &&
                        VideoData.map((video) => (
                            <div
                                key={video.id}
                                className={`col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-30`}
                            >
                                <PopupVideo data={video} />
                            </div>
                        ))}
                    <div className="col"></div>
                </div>
            </div>
        </div>
    );
};

export default SingleVideoContainer;
